import gql from 'graphql-tag';

export const TOGGLE_SAVED_BOTHY_MUTATION = gql`
  mutation ToggleSavedBothy($bothyId: ID!) {
    toggleSavedBothy(bothyId: $bothyId) {
      bothy {
        id
        saved
      }
    }
  }
`;

export const ADD_BOTHY_VISIT_MUTATION = gql`
  mutation AddBothyVisit($bothyId: ID!, $date: DateTime!, $notes: String!) {
    addBothyVisit(bothyId: $bothyId, notes: $notes, date: $date) {
      bothyVisit {
        id
        date
        notes
        bothy {
          id
          name
          imageUrl
          region {
            id
            name
          }
        }
      }
    }
  }
`;
