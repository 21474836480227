import React, { useState, useEffect, useCallback } from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import { useQuery, useMutation } from 'urql';
import useMobile from 'common/hooks/mobile';
import moment from 'moment';
import AddVisitForm from './AddVisitForm';
import { PLACEHOLDER_URL } from 'common/utils/constants';
import { BOTHIES_DROPDOWN_LIST_QUERY } from 'common/graphql/queries';
import { ADD_BOTHY_VISIT_MUTATION } from 'common/graphql/mutations';
import './AddVisitModal.scss';

const AddVisitModal = props => {
  const { isOpen, onClose: onCloseProp, bothyId } = props;

  const [res] = useQuery({
    query: BOTHIES_DROPDOWN_LIST_QUERY,
    pause: !isOpen,
  });

  const [mutationRes, executeMutation] = useMutation(ADD_BOTHY_VISIT_MUTATION);

  const isMobile = useMobile();

  const [date, setDate] = useState(moment());
  const [selectedBothyId, setSelectedBothyId] = useState(bothyId);
  const [notes, setNotes] = useState('');

  const onClose = useCallback(() => {
    setSelectedBothyId(bothyId ? bothyId : null);
    setDate(moment());
    setNotes('');
    onCloseProp();
  }, [bothyId, onCloseProp]);

  useEffect(() => {
    if (!mutationRes.fetching && !mutationRes.error) {
      onClose();
    }
  }, [mutationRes.fetching, mutationRes.error, onClose]);

  useEffect(() => {
    if (bothyId) {
      setSelectedBothyId(bothyId);
    }
  }, [bothyId, setSelectedBothyId]);

  const bothies = res.data ? res.data.bothies.edges.map(x => x.node) : [];
  const bothyOptions = bothies.map(x => ({
    key: x.id,
    value: x.id,
    text: x.name,
  }));

  const onAddClick = () => {
    if (selectedBothyId !== null) {
      executeMutation({
        bothyId: selectedBothyId,
        date: date.toISOString(),
        notes,
      });
    }
  };

  const getBothyImage = () => {
    if (!selectedBothyId || !bothies.length) {
      return PLACEHOLDER_URL;
    }

    const selectedBothy = bothies.find(x => x.id === selectedBothyId);
    return selectedBothy.imageUrl;
  };

  return (
    <Modal
      className="AddVisitModal"
      dimmer="inverted"
      open={isOpen}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>Add a visit</Modal.Header>
      <Modal.Content image>
        <Image wrapped size="medium" src={getBothyImage()} />
        <Modal.Description>
          <AddVisitForm
            bothyOptions={bothyOptions}
            bothyId={selectedBothyId}
            onBothyIdChange={setSelectedBothyId}
            date={date}
            onDateChange={setDate}
            notes={notes}
            onNotesChange={setNotes}
            isMobile={isMobile}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={onClose} disabled={mutationRes.fetching}>
          Cancel
        </Button>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Add"
          disabled={mutationRes.fetching}
          onClick={onAddClick}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default AddVisitModal;
