import moment from 'moment';

const getMonthISOStringFromVisit = visit =>
  visit.date
    .clone()
    .startOf('month')
    .valueOf();

export const groupVisitsByMonth = visits => {
  const groupedVisits = visits.reduce((acc, cur) => {
    const monthISOString = getMonthISOStringFromVisit(cur);

    const existingVisitsForMonth = acc[monthISOString] || [];
    existingVisitsForMonth.push(cur);
    acc[monthISOString] = existingVisitsForMonth;
    return acc;
  }, {});
  return new Map(Object.entries(groupedVisits).sort((a, b) => b[0] - a[0]));
};

export const shouldShowYear = month => !month.isSame(moment(), 'year');
export const shouldShowDivider = month => !month.isSame(moment(), 'month');
