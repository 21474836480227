import React from 'react';
import { useHistory } from 'react-router-dom';
import BothyListContainer from 'bothies/BothyListContainer';
import SearchFilterBar from 'controls/SearchFilterBar';
import { convertBothyFiltersToQueryVariables } from 'common/utils/filters';

const FilterableBothyList = ({
  className,
  filters,
  onFiltersChange,
  onClearFilters,
}) => {
  const history = useHistory();

  const defaultVariables = {
    sort: 'NAME_ASC',
  };

  const queryVariables = {
    ...defaultVariables,
    filters: convertBothyFiltersToQueryVariables(filters),
  };

  return (
    <BothyListContainer
      header={
        <SearchFilterBar
          filters={filters}
          onFiltersChange={onFiltersChange}
          onSuggestionSelected={selection =>
            history.push(`/bothies/${selection.id}`)
          }
        />
      }
      className={className}
      variables={queryVariables}
      emptyMessage={
        <span>
          No bothies found.{' '}
          <span className="link-text" onClick={onClearFilters}>
            Click here
          </span>{' '}
          to remove current filters.
        </span>
      }
    />
  );
};

export default FilterableBothyList;
