import React from 'react';
import { Menu, Icon, Dropdown, Popup, Button } from 'semantic-ui-react';
import { useUser } from 'auth/auth';
import Logo from 'res/logo.png';
import AchievementsPage from 'pages/mobile/AchievementsPage';
import AchievementSummary from 'achievements/AchievementSummary';
import useMobile from 'common/hooks/mobile';
import { useHistory } from 'react-router-dom';
import './AppBar.scss';

const AppBar = props => {
  const { onHamburgerClick, onLogoutClick } = props;

  const isMobile = useMobile();

  const user = useUser();

  const history = useHistory();

  return (
    <Menu icon as="nav" className="AppBar" fixed="top">
      <Menu.Item className="hamburger-item" header onClick={onHamburgerClick}>
        <Icon size="big" name="bars" />
      </Menu.Item>

      <Menu.Item header className="borderless">
        <img alt="bothy bagger logo" src={Logo} />
        <span className="app-name">Bothy Bagger</span>
      </Menu.Item>

      <div className="user-section">
        {!isMobile && (
          <Popup
            wide
            closeOnDocumentClick={false}
            trigger={
              <Menu.Item header>
                <AchievementSummary />
              </Menu.Item>
            }
            content={
              <div className="achievement-popup">
                <AchievementsPage compact />
              </div>
            }
            on="click"
            position="bottom right"
          />
        )}
        {user.isAnonymous ? (
          <Menu.Item>
            <Button
              primary
              icon
              labelPosition="left"
              onClick={() => history.push('/register')}
            >
              <Icon name="user" /> Sign up
            </Button>
          </Menu.Item>
        ) : (
          <Dropdown item as={Icon} icon="user circle outline" size="big">
            <Dropdown.Menu direction="left">
              <Dropdown.Header icon="user" content={user.email} />
              <Dropdown.Divider />
              <Dropdown.Item
                icon="sign-out"
                text="Log Out"
                onClick={onLogoutClick}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </Menu>
  );
};

export default AppBar;
