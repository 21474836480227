import React from 'react';
import { Label } from 'semantic-ui-react';

const VisitCounter = ({ count }) => {
  return (
    <Label className="VisitCounter" size="medium" color="orange">
      Visits
      <Label.Detail>{count}</Label.Detail>
    </Label>
  );
};

export default VisitCounter;
