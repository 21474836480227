import React from 'react';
import BothyListContainer from 'bothies/BothyListContainer';

const SavedTab = () => {
  const queryVariables = {
    filters: { and: [{ isSaved: true }] },
    sort: 'NAME_ASC',
  };

  return (
    <BothyListContainer
      className="DiscoverPage mobile-page"
      variables={queryVariables}
      emptyMessage="No saved bothies"
    />
  );
};

export default SavedTab;
