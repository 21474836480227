import React from 'react';
import { useParams } from 'react-router-dom';
import BothyMap from 'map/BothyMap';

const BothyLocationPage = () => {
  const { id } = useParams();

  return (
    <div className="BothyLocationPage mobile-page">
      <BothyMap focus queryVariables={{ filters: { id } }} />
    </div>
  );
};

export default BothyLocationPage;
