import React from 'react';
import { Icon } from 'semantic-ui-react';
import './Achievement.scss';

const UnlockedAchievement = ({ name, children, onClick, compact = false }) => {
  const iconSize = compact ? undefined : 'large';

  const clickable = !!onClick;
  return (
    <div className="UnlockedAchievement Achievement" onClick={onClick}>
      <Icon.Group size="huge">
        <Icon
          className={clickable ? 'clickable' : ''}
          name="certificate"
          size={iconSize}
        />
        {children}
      </Icon.Group>
      {!compact && <p>{name}</p>}
    </div>
  );
};

export default UnlockedAchievement;
