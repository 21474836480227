import React from 'react';
import classNames from 'classnames';
import { SemanticToastContainer, toast } from 'react-semantic-toasts';
import useMobile from 'common/hooks/mobile';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import 'semantic-ui-css/components/reset.min.css';
import 'semantic-ui-css/components/site.min.css';
import 'semantic-ui-css/components/container.min.css';
import 'semantic-ui-css/components/icon.min.css';
import 'semantic-ui-css/components/message.min.css';
import 'semantic-ui-css/components/header.min.css';

export const displayErrorToast = ({ title, description }) =>
  toast({
    type: 'error',
    icon: 'times circle',
    title,
    description,
    time: 5000,
  });

const ToastContainer = ({ children }) => {
  const mobile = useMobile();
  return (
    <>
      {children}
      <SemanticToastContainer
        className={classNames('ToastContainer', { mobile })}
        position="bottom-center"
        animation="fly up"
      />
    </>
  );
};

export default ToastContainer;
