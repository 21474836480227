import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import AppBar from 'AppBar';
import Sidebar from 'Sidebar';
import TripsPage from 'pages/mobile/TripsPage';
import DiscoverPage from 'pages/mobile/DiscoverPage';
import AchievementsPage from 'pages/mobile/AchievementsPage';
import BothyDetailPage from 'pages/mobile/BothyDetailPage';
import MapPage from 'pages/mobile/MapPage';
import BothyLocationPage from 'pages/mobile/BothyLocationPage';
import Banner from 'Banner';

export const sidebarRoutes = [
  {
    title: 'Discover',
    path: '/',
    component: DiscoverPage,
    sidebarIcon: 'binoculars',
  },
  {
    title: 'Map',
    path: '/map',
    component: MapPage,
    sidebarIcon: 'map marker alternate',
  },
  {
    title: 'Trips',
    path: '/trips/:tab',
    component: TripsPage,
    sidebarIcon: 'compass outline',
    navigatePath: '/trips/visits',
    activePath: '/trips/',
  },
  {
    title: 'Achievements',
    path: '/achievements',
    component: AchievementsPage,
    sidebarIcon: 'gem outline',
  },
];

const mobileRoutes = [
  ...sidebarRoutes,
  {
    title: 'Details',
    path: '/bothies/:id',
    component: BothyDetailPage,
  },
  {
    title: 'Map',
    path: '/bothies/:id/location',
    component: BothyLocationPage,
  },
];

const MobileRoot = props => {
  const { onLogoutClick } = props;

  const [sidebarVisible, setSidebarVisible] = useState(false);

  return (
    <div className="Root mobile">
      <Sidebar
        isVisible={sidebarVisible}
        onSidebarHide={() => setSidebarVisible(false)}
      >
        <AppBar
          onHamburgerClick={() => setSidebarVisible(!sidebarVisible)}
          onLogoutClick={onLogoutClick}
        />
        <Banner />
        <div className="scrollable">
          <div className="root-content">
            {mobileRoutes.map(r => (
              <Route key={r.path} exact path={r.path} component={r.component} />
            ))}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default MobileRoot;
