import gql from 'graphql-tag';

export const BOTHY_VISITS_QUERY = gql`
  query {
    bothyVisits {
      edges {
        node {
          id
          date
          bothy {
            id
            name
            imageUrl
            region {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const BOTHY_DETAILS_QUERY = gql`
  query($id: ID!) {
    bothy(id: $id) {
      id
      name
      summary
      description
      imageUrl
      saved
      size
      region {
        id
        name
      }
      bothyVisits {
        edges {
          node {
            id
            date
            notes
            achievements {
              totalCount
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const BOTHIES_LIST_QUERY = gql`
  query($filters: BothyFilter, $sort: [BothySortEnum]) {
    bothies(filters: $filters, sort: $sort) {
      edges {
        node {
          id
          name
          summary
          imageUrl
          size
          saved
          region {
            id
            name
          }
          bothyVisits {
            totalCount
          }
        }
      }
    }
  }
`;

export const BOTHIES_DROPDOWN_LIST_QUERY = gql`
  query {
    bothies(sort: NAME_ASC) {
      edges {
        node {
          id
          name
          imageUrl
        }
      }
    }
  }
`;

export const BOTHIES_LOCATION_QUERY = gql`
  query($filters: BothyFilter) {
    bothies(filters: $filters) {
      edges {
        node {
          id
          name
          summary
          latitude
          longitude
        }
      }
    }
  }
`;

export const HOME_SUGGESTION_SEARCH_QUERY = gql`
  query($searchTerm: String!) {
    regions(filters: { nameIlike: $searchTerm }, first: 2) {
      edges {
        node {
          id
          name
        }
      }
    }
    bothies(filters: { nameIlike: $searchTerm }, first: 6) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const BOTHY_SUGGESTION_SEARCH_QUERY = gql`
  query($searchTerm: String!) {
    bothies(filters: { nameIlike: $searchTerm }, first: 8) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const ACHIEVEMENT_PAGE_QUERY = gql`
  query {
    achievementGroups {
      edges {
        node {
          id
          name
          description
          groupSize
          achievements(filters: { isUnlocked: true }) {
            edges {
              node {
                id
                name
                description
                icon
              }
            }
            totalCount
          }
        }
      }
    }
  }
`;

export const ACHIEVEMENT_DETAILS_QUERY = gql`
  query($id: ID!) {
    achievements(filters: { id: $id }) {
      edges {
        node {
          id
          name
          description
          icon
          unlockedAt
          groupId
          bothyVisit {
            id
            bothy {
              id
              name
            }
            date
          }
        }
      }
    }
  }
`;

export const ACHIEVEMENT_SUMMARY_QUERY = gql`
  query {
    achievements(filters: { isUnlocked: true }) {
      totalCount
    }
  }
`;
