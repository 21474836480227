import React, { useMemo } from 'react';
import moment from 'moment';
import { Divider } from 'semantic-ui-react';
import {
  groupVisitsByMonth,
  shouldShowDivider,
  shouldShowYear,
} from './timeline';
import { getMonthWithYearFromDate, getMonthFromDate } from 'common/utils/dates';
import useMobile from 'common/hooks/mobile';
import BothyCard from 'bothies/BothyCard';
import VisitDate from 'visits/VisitDate';

const BothyVisitTimeline = ({ visits, onBothyClick }) => {
  const isMobile = useMobile();

  const memoizedTimeline = useMemo(() => {
    const groupedVisits = groupVisitsByMonth(visits);

    let timeline = [];

    for (const key of groupedVisits.keys()) {
      const momentKey = moment(parseInt(key, 10));

      if (shouldShowDivider(momentKey)) {
        const dividerText = shouldShowYear(momentKey)
          ? getMonthWithYearFromDate(momentKey)
          : getMonthFromDate(momentKey);

        timeline.push(
          <Divider key={key} horizontal>
            {dividerText}
          </Divider>
        );
      }

      const visits = groupedVisits.get(key);
      timeline = timeline.concat(
        visits.map(x => (
          <BothyCard
            key={x.id}
            bothy={x.bothy}
            onCardClick={() => onBothyClick(x.bothy.id)}
            isMobile={isMobile}
          >
            <VisitDate color="primary" date={x.date} />
          </BothyCard>
        ))
      );
    }
    return timeline;
  }, [visits, isMobile, onBothyClick]);

  return <div className="BothyList">{memoizedTimeline}</div>;
};

const BothyVisitTimelinePlaceholder = () => (
  <div className="BothyList">
    <BothyCard.Placeholder />
    <BothyCard.Placeholder />
    <BothyCard.Placeholder />
    <BothyCard.Placeholder />
    <BothyCard.Placeholder />
  </div>
);

BothyVisitTimeline.Placeholder = BothyVisitTimelinePlaceholder;

export default BothyVisitTimeline;
