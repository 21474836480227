import React, { useState, useCallback } from 'react';
import { Divider, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import { useQuery } from 'urql';
import AchievementSection from 'achievements/AchievementSection';
import UnlockedAchievement from 'achievements/UnlockedAchievement';
import LoadingWrapper from 'common/components/LoadingWrapper';
import ViewAchievementModal from 'achievements/ViewAchievementModal';
import { ACHIEVEMENT_PAGE_QUERY } from 'common/graphql/queries';
import {
  extractNodes,
  convertAchievementIconToComponent,
} from 'common/utils/graphql';
import './AchievementsPage.scss';
import AnonymousUserActionGuard from 'common/components/AnonymousUserActionGuard';

const AchievementsPage = ({ compact }) => {
  const [res] = useQuery({
    query: ACHIEVEMENT_PAGE_QUERY,
  });

  const groups = res.data ? extractNodes(res.data.achievementGroups) : [];

  const [selectedAchievement, setSelectedAchievement] = useState(false);

  const memoizedOnViewAchievementModalClose = useCallback(
    () => setSelectedAchievement(null),
    [setSelectedAchievement]
  );

  // TODO: Make a placeholder component for achievement sections

  return (
    <div className={classNames('AchievementsPage mobile-page', { compact })}>
      <LoadingWrapper res={res} placeholder={<Loader active />}>
        <>
          <AnonymousUserActionGuard action="start earning achievements">
            {groups.map((group, index) => (
              <React.Fragment key={group.id}>
                <AchievementSection
                  key={group.id}
                  title={group.name}
                  subtitle={group.description}
                  size={group.groupSize}
                  paged={compact}
                >
                  {extractNodes(group.achievements).map(achievement => (
                    <UnlockedAchievement
                      key={achievement.id}
                      name={achievement.name}
                      onClick={() => setSelectedAchievement(achievement.id)}
                      compact={compact}
                    >
                      {convertAchievementIconToComponent(achievement.icon, {
                        compact,
                        clickable: true,
                      })}
                    </UnlockedAchievement>
                  ))}
                </AchievementSection>
                {index !== groups.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            <ViewAchievementModal
              isOpen={!!selectedAchievement}
              onClose={memoizedOnViewAchievementModalClose}
              achievementId={selectedAchievement}
            />
          </AnonymousUserActionGuard>
        </>
      </LoadingWrapper>
    </div>
  );
};

export default AchievementsPage;
