import { dedupExchange, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';
import {
  toggleSavedBothyOptimisticUpdater,
  addBothyVisitUpdater,
} from './updaters';
import {
  bothyVisitResolver,
  achievementResolver,
  bothyResolver,
} from './resolvers';

const configuredCacheExchange = cacheExchange({
  optimistic: {
    toggleSavedBothy: toggleSavedBothyOptimisticUpdater,
  },
  updates: {
    Mutation: {
      addBothyVisit: addBothyVisitUpdater,
    },
  },
  resolvers: {
    BothyVisit: bothyVisitResolver,
    Achievement: achievementResolver,
    Bothy: bothyResolver,
  },
});

const exchanges = [
  devtoolsExchange,
  dedupExchange,
  configuredCacheExchange,
  fetchExchange,
];

export default exchanges;
