import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import classNames from 'classnames';
import VisitCounter from 'visits/VisitCounter';
import { convertBothySizeEnumToText } from 'common/utils/graphql';
import './BothyInfoBar.scss';
import SavedIcon from 'common/components/SavedIcon';

const BothyInfoBar = ({ bothy, onSaveButtonClick, vertical }) => {
  const bothySizeText = convertBothySizeEnumToText(bothy.size);
  return (
    <div className={classNames('BothyInfoBar', { vertical })}>
      <div className="secondary">
        <Label basic circular className="size-indicator" size="small">
          <Icon name="users" /> {bothySizeText}
        </Label>
      </div>
      <div className="primary">
        {onSaveButtonClick && (
          <SavedIcon
            className="save-icon"
            saved={bothy.saved}
            onClick={onSaveButtonClick}
          />
        )}
        <VisitCounter count={bothy.bothyVisits.totalCount} />
      </div>
    </div>
  );
};

export default BothyInfoBar;
