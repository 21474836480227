import React from 'react';
import { Card } from 'semantic-ui-react';
import BothyCard from './BothyCard';
import BothyInfoBar from './bothy-details/BothyInfoBar';
import useMobile from 'common/hooks/mobile';
import './BothyList.scss';

const BothyList = props => {
  const { bothies, onBothyClick, onSaveBothyClick } = props;
  const isMobile = useMobile();

  const generateSaveButtonHandler = bothyId => e => {
    e.stopPropagation();
    onSaveBothyClick({ bothyId });
  };

  return (
    <div className="BothyList">
      <Card.Group itemsPerRow={1}>
        {bothies.map(x => (
          <BothyCard
            key={x.id}
            bothy={x}
            onCardClick={() => onBothyClick(x.id)}
            isMobile={isMobile}
          >
            <BothyInfoBar
              vertical={isMobile}
              bothy={x}
              onSaveButtonClick={
                !isMobile ? generateSaveButtonHandler(x.id) : null
              }
            />
          </BothyCard>
        ))}
      </Card.Group>
    </div>
  );
};

export const BothyListPlaceholder = () => {
  const isMobile = useMobile();

  return (
    <div className="BothyList">
      <Card.Group itemsPerRow={1}>
        <BothyCard.Placeholder isMobile={isMobile} />
        <BothyCard.Placeholder isMobile={isMobile} />
        <BothyCard.Placeholder isMobile={isMobile} />
        <BothyCard.Placeholder isMobile={isMobile} />
        <BothyCard.Placeholder isMobile={isMobile} />
      </Card.Group>
    </div>
  );
};

BothyList.Placeholder = BothyListPlaceholder;

export default BothyList;
