import React from 'react';
import { Icon } from 'semantic-ui-react';
import './Achievement.scss';

const LockedAchievement = ({ compact }) => {
  const iconSize = compact ? undefined : 'large';
  return (
    <div className="LockedAchievement Achievement">
      <Icon.Group size="huge">
        <Icon name="certificate" size={iconSize} />
        {true && <Icon name="lock" size={compact ? 'mini' : 'tiny'} />}
      </Icon.Group>
      {!compact && <p>Locked</p>}
    </div>
  );
};

export default LockedAchievement;
