import React, { useRef, useMemo, useState } from 'react';
import ReactMapboxGl, { GeoJSONLayer } from 'react-mapbox-gl';
import elementResizeEvent from 'element-resize-event';

const Map = ReactMapboxGl({
  accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
});

const LOCATION_COLOR = '#eea724';

const MapboxMapProvider = props => {
  const {
    lat,
    lng,
    zoom,
    bounds,
    geoJsonData,
    onFeatureClick,
    children,
  } = props;

  const mapRef = useRef(null);
  const [mapAPI, setMapAPI] = useState(null);

  const memoizedCenter = useMemo(() => [lng, lat], [lng, lat]);
  const memoizedZoom = useMemo(() => [zoom], [zoom]);

  const initialiseMap = mapInstance => {
    if (mapRef.current) {
      elementResizeEvent(mapRef.current.container, () => {
        setTimeout(() => {
          mapInstance.resize();
        });
      });
    }

    setMapAPI(mapInstance);
  };

  const onFeatureMouseEnter = () => {
    mapAPI.getCanvas().style.cursor = 'pointer';
  };

  const onFeatureMouseExit = () => {
    mapAPI.getCanvas().style.cursor = '';
  };

  return (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/outdoors-v11"
      containerStyle={{
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
      }}
      maxBounds={bounds}
      center={memoizedCenter}
      zoom={memoizedZoom}
      onStyleLoad={initialiseMap}
      ref={mapRef}
    >
      <GeoJSONLayer
        data={geoJsonData}
        circlePaint={{
          'circle-color': LOCATION_COLOR,
          'circle-radius': 6,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#000',
        }}
        circleOnClick={onFeatureClick}
        circleOnMouseEnter={onFeatureMouseEnter}
        circleOnMouseLeave={onFeatureMouseExit}
      />
      {children}
    </Map>
  );
};

export default MapboxMapProvider;
