import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';
import { Popup } from 'react-mapbox-gl';
import { mapBothiesToGeoJson } from 'common/utils/geoJson';
import { extractNodes } from 'common/utils/graphql';
import { BOTHIES_LOCATION_QUERY } from 'common/graphql/queries';
import Map from './Map';
import BothyPopup from './BothyPopup';
import './BothyMap.scss';

const INITIAL_MAP_LAT = 56.654781;
const INITIAL_MAP_LNG = -4.995348;
const INITIAL_MAP_ZOOM = 6.5;

const BothyMap = ({ focus, queryVariables }) => {
  const [res] = useQuery({
    query: BOTHIES_LOCATION_QUERY,
    variables: queryVariables,
    requestPolicy: 'cache-and-network',
  });

  const [previewBothy, setPreviewBothy] = useState(null);

  const memoizedBothies = useMemo(() => {
    return res.data ? extractNodes(res.data.bothies) : [];
  }, [res.data]);

  const memoizedGeoJsonData = useMemo(
    () => mapBothiesToGeoJson(memoizedBothies),
    [memoizedBothies]
  );

  useEffect(() => {
    if (!previewBothy) {
      return;
    }

    const selectedBothyVisible = memoizedBothies.some(
      x => x.id === previewBothy.id
    );

    if (!selectedBothyVisible) {
      setPreviewBothy(null);
    }
  }, [memoizedBothies, setPreviewBothy, previewBothy]);

  const mapState =
    focus && memoizedBothies.length === 1
      ? {
          lat: memoizedBothies[0].latitude,
          lng: memoizedBothies[0].longitude,
          zoom: 11,
        }
      : {
          lat: INITIAL_MAP_LAT,
          lng: INITIAL_MAP_LNG,
          zoom: INITIAL_MAP_ZOOM,
        };

  const handleBothyClick = e => {
    const [
      {
        properties,
        geometry: { coordinates },
      },
    ] = e.features;
    setPreviewBothy({ ...properties, coordinates });
  };

  return (
    <div className="BothyMap">
      <Map
        lat={mapState.lat}
        lng={mapState.lng}
        zoom={mapState.zoom}
        geoJsonData={memoizedGeoJsonData}
        onFeatureClick={handleBothyClick}
      >
        {previewBothy && (
          <Popup coordinates={previewBothy.coordinates} closeButton={true}>
            <BothyPopup
              id={previewBothy.id}
              name={previewBothy.name}
              summary={previewBothy.summary}
              onCloseClick={() => setPreviewBothy(null)}
            />
          </Popup>
        )}
      </Map>
    </div>
  );
};

export default BothyMap;
