import React, { useState, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import AddVisitModal from 'visits/AddVisitModal';
import HomePage from 'pages/desktop/HomePage';
import AppBar from 'AppBar';
import MobileRoot from './MobileRoot';
import useMobile from 'common/hooks/mobile';
import { useUser } from 'auth/auth';
import { isDevelopmentMode } from 'common/utils/env';
import ToastContainer from 'common/components/ToastContainer';
import Banner from 'Banner';
import './Root.scss';

const optimizelySdkKey = isDevelopmentMode()
  ? 'MKEErB6WM9EpMLEDWodu7'
  : 'QnJnqBfUWm14e1GQtZbGe';

const optimizely = createInstance({
  sdkKey: optimizelySdkKey,
});

const DesktopRoot = props => {
  const { onLogoutClick } = props;

  const [isAddVisitModalOpen, setIsAddVisitModalOpen] = useState(false);

  const memoizedOnAddVisitModalClose = useCallback(
    () => setIsAddVisitModalOpen(false),
    [setIsAddVisitModalOpen]
  );

  return (
    <div className="Root desktop">
      <AppBar
        onLogoutClick={onLogoutClick}
        onAddVisitClick={() => setIsAddVisitModalOpen(true)}
      />
      <Banner />
      <div className="root-content with-banner">
        <Route path="/" component={HomePage} />
      </div>
      <AddVisitModal
        isOpen={isAddVisitModalOpen}
        onClose={memoizedOnAddVisitModalClose}
      />
    </div>
  );
};

const Root = props => {
  const user = useUser();
  const isMobile = useMobile();

  const deviceRoot = isMobile ? (
    <MobileRoot {...props} />
  ) : (
    <DesktopRoot {...props} />
  );

  return (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: user.email,
      }}
      timeout={1000}
    >
      <ToastContainer>{deviceRoot}</ToastContainer>
    </OptimizelyProvider>
  );
};

export default Root;
