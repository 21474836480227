import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const MOBILE_BREAKPOINT_PX = 600;

export const compareToBreakpoint = width => width <= MOBILE_BREAKPOINT_PX;

const useMobile = () => {
  const [isMobile, setIsMobile] = useState(() =>
    compareToBreakpoint(window.innerWidth)
  );

  useEffect(() => {
    const calculateWidth = throttle(
      () => setIsMobile(compareToBreakpoint(window.innerWidth)),
      200
    );
    window.addEventListener('resize', calculateWidth);
    return () => window.removeEventListener('resize', calculateWidth);
  });

  return isMobile;
};

export default useMobile;
