import React from 'react';
import { Tab } from 'semantic-ui-react';
import { useParams, useHistory } from 'react-router-dom';
import VisitsTab from './trip-tabs/VisitsTab';
import SavedTab from './trip-tabs/SavedTab';
import './TripsPage.scss';

const ACTIVE_TAB_ID = 'tripsPageActiveTab';

const tabUrlParams = ['visits', 'saved'];

const TripsPage = () => {
  const history = useHistory();
  const { tab } = useParams();

  const tabIndex = tabUrlParams.indexOf(tab);

  const panes = [
    {
      menuItem: {
        key: 'visits',
        icon: 'calendar check outline',
        content: 'Visits',
      },
      render: () => (
        <Tab.Pane className="mobile-page" id={ACTIVE_TAB_ID}>
          <VisitsTab />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 'saved',
        icon: 'bookmark',
        content: 'Saved',
      },
      render: () => (
        <Tab.Pane className="mobile-page" id={ACTIVE_TAB_ID}>
          <SavedTab />
        </Tab.Pane>
      ),
    },
  ];

  const scrollTabToTop = () => {
    const activeTab = document.getElementById(ACTIVE_TAB_ID);
    activeTab.scrollTop = 0;
  };

  const handleTabChange = (e, { activeIndex }) => {
    history.push(tabUrlParams[activeIndex]);
    scrollTabToTop();
  };

  return (
    <Tab
      className="TripsPage"
      activeIndex={tabIndex !== -1 ? tabIndex : 0}
      menu={{ secondary: true, pointing: true, className: 'tab-menu' }}
      panes={panes}
      onTabChange={handleTabChange}
    />
  );
};

export default TripsPage;
