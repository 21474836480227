import React from 'react';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

export const extractNodes = queryData => queryData.edges.map(edge => edge.node);

export const convertBothySizeEnumToText = bothySize => {
  if (bothySize === 'VERY_SMALL') {
    return 'Tiny';
  } else if (bothySize === 'SMALL') {
    return 'Small';
  } else if (bothySize === 'MEDIUM') {
    return 'Medium';
  } else if (bothySize === 'LARGE') {
    return 'Large';
  } else {
    throw Error('Invalid BothySize enum value');
  }
};

export const convertAchievementIconToComponent = (iconString, options = {}) => {
  const [iconType, iconName] = iconString.split(':');

  const defaultOptions = {
    compact: false,
    clickable: false,
  };

  const { clickable, compact } = { ...defaultOptions, ...options };

  if (iconType === 'icon') {
    return (
      <Icon
        className={clickable ? 'clickable' : ''}
        name={iconName}
        size={compact ? 'tiny' : 'small'}
      />
    );
  } else if (iconType === 'text') {
    return (
      <Icon
        className={classNames('text', { clickable })}
        size={compact ? 'mini' : 'tiny'}
      >
        {iconName}
      </Icon>
    );
  } else {
    throw Error('Invalid Achievement icon value');
  }
};
