import React, { useState } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import LockedAchievement from './LockedAchievement';
import './AchievementSection.scss';

const PAGE_SIZE = 3;

const AchievementSection = props => {
  const { paged, title, subtitle, size, children } = props;

  const locked = Array.from(Array(size - React.Children.count(children)));

  const lockedAchievements = locked.map((_, i) => (
    <LockedAchievement key={i} compact={paged} />
  ));

  const achievements = [...children, ...lockedAchievements];

  return (
    <div className="AchievementSection">
      <Header size={paged ? undefined : 'large'}>
        {title}
        <Header.Subheader>{subtitle}</Header.Subheader>
      </Header>
      <div className="achievements">
        {paged ? (
          <PagedAchievementList achievements={achievements} />
        ) : (
          achievements
        )}
      </div>
    </div>
  );
};

const PagedAchievementList = props => {
  const { achievements } = props;

  const [currentIndex, setCurrentIndex] = useState(0);

  const hasPreviousPage = currentIndex !== 0;
  const hasNextPage = currentIndex + PAGE_SIZE < achievements.length;

  const nextPage = () => {
    setCurrentIndex(currentIndex + PAGE_SIZE);
  };

  const previousPage = () => {
    setCurrentIndex(currentIndex - PAGE_SIZE);
  };

  const currentPage = achievements.slice(
    currentIndex,
    currentIndex + PAGE_SIZE
  );

  return (
    <>
      {hasPreviousPage && (
        <Icon
          className="page-button"
          name="angle left"
          size="big"
          onClick={previousPage}
          color="grey"
        />
      )}
      <div className="achievement-page">{currentPage}</div>
      {hasNextPage && (
        <Icon
          className="page-button"
          name="angle right"
          size="big"
          onClick={nextPage}
          color="grey"
        />
      )}
    </>
  );
};

export default AchievementSection;
