import React from 'react';
import { Label } from 'semantic-ui-react';
import { formatDateForShortDisplay } from 'common/utils/dates';

const VisitDate = ({ color, date }) => {
  return (
    <Label
      className="VisitDate"
      size="medium"
      color={color === 'accent' ? 'brown' : 'orange'}
    >
      {formatDateForShortDisplay(date)}
    </Label>
  );
};

export default VisitDate;
