import React, { useState } from 'react';
import FilterableBothyList from 'bothies/FilterableBothyList';
import './DiscoverPage.scss';

const DiscoverPage = () => {
  const defaultFilterState = {
    visited: false,
    notVisited: false,
    saved: false,
  };

  const [filters, setFilters] = useState(defaultFilterState);

  return (
    <FilterableBothyList
      className="DiscoverPage mobile-page"
      filters={filters}
      onFiltersChange={setFilters}
      onClearFilters={() => setFilters(defaultFilterState)}
    />
  );
};

export default DiscoverPage;
