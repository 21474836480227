import React, { useState } from 'react';
import { Divider, Form, Icon, Message } from 'semantic-ui-react';
import {
  AuthenticationError,
  AuthenticationErrorCodes,
  convertAnonymousUserToGoogleUser,
  convertAnonymousUserToPasswordUser,
  signInWithGoogle,
  signUpWithEmailAndPassword,
} from './auth';
import { Link, useHistory } from 'react-router-dom';
import LoginHeader from './LoginHeader';
import './LoginForm.scss';

const Register = ({ user }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [error, setError] = useState('');

  const history = useHistory();

  if (user && !user.isAnonymous) {
    history.replace('/');
  }

  const clearPassword = () => {
    setPassword('');
    setConfirmPassword('');
  };

  const handleCreateAccountClick = async () => {
    setError('');

    if (!email || !password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      clearPassword();
      setError('Passwords must match');
      return;
    }

    const emailHandler =
      user && user.isAnonymous
        ? convertAnonymousUserToPasswordUser
        : signUpWithEmailAndPassword;

    try {
      await emailHandler(email, password);

      window.location.reload();
    } catch (error) {
      if (error instanceof AuthenticationError) {
        if (error.code === AuthenticationErrorCodes.EMAIL_EXISTS_ALREADY) {
          setError(
            <>
              An account with this email address already exists. Have you{' '}
              <Link to="/forgotpassword">forgotten your password?</Link>
            </>
          );
        } else if (error.code === AuthenticationErrorCodes.WEAK_PASSWORD) {
          setError(error.message);
        }
        clearPassword();
      }
    }
  };

  return (
    <div className="Register">
      <LoginHeader />
      <Form error={!!error}>
        <Message error content={error} />
        <Form.Input
          type="email"
          required
          placeholder="Email address"
          value={email}
          onChange={(_, { value }) => setEmail(value)}
        />
        <Form.Input
          type="password"
          required
          placeholder="Password"
          value={password}
          onChange={(_, { value }) => setPassword(value)}
        />
        <Form.Input
          type="password"
          required
          placeholder="Confirm password"
          value={confirmPassword}
          onChange={(_, { value }) => setConfirmPassword(value)}
        />
        <Form.Button
          primary
          icon
          fluid
          labelPosition="left"
          onClick={handleCreateAccountClick}
        >
          Create account
        </Form.Button>
        {user ? (
          <>
            <Divider horizontal>Or</Divider>
            <Form.Button
              color="blue"
              icon
              fluid
              labelPosition="left"
              onClick={convertAnonymousUserToGoogleUser}
            >
              <Icon name="google" /> Sign in with Google
            </Form.Button>
          </>
        ) : (
          <>
            <Form.Button
              color="blue"
              icon
              fluid
              labelPosition="left"
              onClick={signInWithGoogle}
            >
              <Icon name="google" /> Sign in with Google
            </Form.Button>
            <Divider />
            <Form.Button
              icon
              fluid
              labelPosition="left"
              onClick={() => history.push('/')}
            >
              Back
            </Form.Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default Register;
