import React, { useLayoutEffect, useRef, useState } from 'react';
import { Image, Placeholder, Visibility } from 'semantic-ui-react';

const LazyImage = props => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const viewHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight
      );
      const visible = !(rect.bottom < 0 || rect.top - viewHeight >= 0);

      if (visible) {
        setIsVisible(true);
      }
    }
  }, []);

  if (props.src && props.src.startsWith('data:')) {
    return <Image {...props} />;
  }

  if (!isVisible) {
    return (
      <div className="LazyImage" ref={ref}>
        <Visibility onOnScreen={() => setIsVisible(true)}>
          <Placeholder className="image">
            <Placeholder.Image />
          </Placeholder>
        </Visibility>
      </div>
    );
  }

  return <Image {...props} />;
};

export default LazyImage;
