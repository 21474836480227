import { useEffect } from 'react';
import { useMutation } from 'urql';
import { displayErrorToast } from 'common/components/ToastContainer';

const useMutationErrorToast = ({ res, message }) => {
  useEffect(() => {
    if (res.error) {
      displayErrorToast({
        title: 'Something went wrong',
        description: message,
      });
    }
  }, [message, res.error]);
};

export const useMutationWithErrorToast = ({ mutation, errorMessage }) => {
  const [res, execute] = useMutation(mutation);

  useMutationErrorToast({
    res,
    message: errorMessage,
  });

  return execute;
};
