import React, { useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import { sendPasswordResetEmail } from './auth';
import { useHistory } from 'react-router-dom';
import LoginHeader from './LoginHeader';
import './LoginForm.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  const onSubmit = async () => {
    try {
      await sendPasswordResetEmail(email);
      setSent(true);
    } catch (error) {
      if (error.code && error.code === 'auth/user-not-found') {
        setError('No account found for email address');
      }
    }
  };

  return (
    <div className="ForgotPassword">
      <LoginHeader />
      {sent ? (
        <div>
          <p>An email has been sent to {email}.</p>
          <p>
            Please click the link in this email to reset your password, then
            login with your new password.
          </p>
        </div>
      ) : (
        <Form error={!!error}>
          <Message error content={error} />
          <Form.Input
            type="email"
            required
            placeholder="Email address"
            value={email}
            onChange={(_, { value }) => setEmail(value)}
          />
          <Form.Button primary onClick={onSubmit}>
            Reset password
          </Form.Button>
        </Form>
      )}
      <Button onClick={() => history.push('/')}>Back</Button>
    </div>
  );
};

export default ForgotPassword;
