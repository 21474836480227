import React, { useState } from 'react';
import BothyMap from 'map/BothyMap';
import { convertBothyFiltersToQueryVariables } from 'common/utils/filters';
import BothyFilterPanel from 'controls/BothyFilterPanel';
import './MapPage.scss';

const MapPage = () => {
  const defaultFilterState = {
    visited: false,
    notVisited: false,
    saved: false,
  };

  const defaultVariables = {
    sort: 'NAME_ASC',
  };

  const [filters, setFilters] = useState(defaultFilterState);

  const queryVariables = {
    ...defaultVariables,
    filters: convertBothyFiltersToQueryVariables(filters),
  };

  return (
    <div className="MapPage mobile-page">
      <BothyFilterPanel
        filters={filters}
        onFiltersChange={setFilters}
        showClearFiltersButton={false}
      />
      <BothyMap queryVariables={queryVariables} />
    </div>
  );
};

export default MapPage;
