import React, { useCallback, useState } from 'react';
import { useQuery } from 'urql';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useMutationWithErrorToast } from 'common/hooks/mutation';
import BothyDetails from 'bothies/bothy-details/BothyDetails';
import AddVisitModal from 'visits/AddVisitModal';
import LoadingWrapper from 'common/components/LoadingWrapper';
import { BOTHY_DETAILS_QUERY } from 'common/graphql/queries';
import {
  TOGGLE_SAVED_BOTHY_MUTATION,
  ADD_BOTHY_VISIT_MUTATION,
} from 'common/graphql/mutations';
import useMobile from 'common/hooks/mobile';
import './BothyDetailPage.scss';

const BothyDetailPage = () => {
  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();

  const isMobile = useMobile();

  const [res] = useQuery({
    query: BOTHY_DETAILS_QUERY,
    variables: {
      id,
    },
  });

  const executeAddBothyVisit = useMutationWithErrorToast({
    mutation: ADD_BOTHY_VISIT_MUTATION,
    errorMessage:
      'An error occurred while trying to add the visit, please try again.',
  });

  const executeToggleSavedBothy = useMutationWithErrorToast({
    mutation: TOGGLE_SAVED_BOTHY_MUTATION,
    errorMessage:
      'An error occurred while trying to save the bothy, please try again.',
  });

  const [isAddVisitModalOpen, setIsAddVisitModalOpen] = useState(false);

  const memoizedOnAddVisitModalClose = useCallback(
    () => setIsAddVisitModalOpen(false),
    [setIsAddVisitModalOpen]
  );

  const handleAddVisitFormSubmit = ({ date, notes }) =>
    executeAddBothyVisit({
      bothyId: id,
      date: date.toISOString(),
      notes,
    });

  return (
    <LoadingWrapper res={res} placeholder={<BothyDetails.Placeholder />}>
      {res.data && (
        <>
          <BothyDetails
            isMobile={isMobile}
            bothy={res.data.bothy}
            onSaveButtonClick={() =>
              executeToggleSavedBothy({ bothyId: res.data.bothy.id })
            }
            onViewLocationClick={() =>
              history.push(`${location.pathname}/location`)
            }
            onAddVisitClick={() => setIsAddVisitModalOpen(true)}
            onAddVisitFormSubmit={handleAddVisitFormSubmit}
            onBackClick={() => history.goBack()}
          />
          <AddVisitModal
            isOpen={isAddVisitModalOpen}
            onClose={memoizedOnAddVisitModalClose}
            bothyId={res.data.bothy.id}
          />
        </>
      )}
    </LoadingWrapper>
  );
};

export default BothyDetailPage;
