import React, { useEffect, useState } from 'react';
import { useFeature } from 'common/hooks/feature';
import { Icon } from 'semantic-ui-react';
import SanitizedHTML from 'react-sanitized-html';

const Banner = () => {
  const {
    enabled,
    variables: { message },
  } = useFeature('banner');

  useEffect(() => {
    setVisible(enabled);
  }, [enabled]);

  const [visible, setVisible] = useState(enabled);

  if (!visible) {
    return null;
  }

  return (
    <div className="Banner">
      <div className="banner-message">
        <SanitizedHTML
          allowedAttributes={{ a: ['href'] }}
          allowedTags={['a']}
          html={message}
        />
      </div>
      <Icon
        className="clickable"
        name="close"
        onClick={() => setVisible(false)}
      />
    </div>
  );
};

export default Banner;
