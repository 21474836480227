import React, { useState } from 'react';
import classNames from 'classnames';
import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import BothyMap from 'map/BothyMap';
import BothyDetailPage from 'pages/mobile/BothyDetailPage';
import FilterableBothyList from 'bothies/FilterableBothyList';
import { convertBothyFiltersToQueryVariables } from 'common/utils/filters';
import './HomePage.scss';

const HomePage = () => {
  const location = useLocation();

  const minor = location.pathname !== '/';

  const defaultFilterState = {
    visited: false,
    notVisited: false,
    saved: false,
  };

  const [filters, setFilters] = useState(defaultFilterState);

  const bothyDetailsRoute = useRouteMatch('/bothies/:id');

  const bothyDetailsId = bothyDetailsRoute ? bothyDetailsRoute.params.id : null;

  return (
    <div className="HomePage desktop-page">
      <div
        className={classNames('info-pane', {
          minor,
        })}
      >
        <Switch>
          <Route path="/" exact={true}>
            <FilterableBothyList
              className="DiscoverPage mobile-page"
              filters={filters}
              onFiltersChange={setFilters}
              onClearFilters={() => setFilters(defaultFilterState)}
            />
          </Route>
          <Route path="/bothies/:id" component={BothyDetailPage} />
        </Switch>
      </div>
      <BothyMap
        focus={!!bothyDetailsId}
        queryVariables={{
          filters: bothyDetailsId
            ? { id: bothyDetailsId }
            : convertBothyFiltersToQueryVariables(filters),
        }}
      />
    </div>
  );
};

export default HomePage;
