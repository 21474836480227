export const convertBothyFiltersToQueryVariables = filters => {
  if (filters === {}) {
    return null;
  }

  const andCriterion = [];

  if (filters.saved) {
    andCriterion.push({
      isSaved: true,
    });
  }

  if (filters.visited && filters.notVisited) {
    return { and: andCriterion };
  }

  if (filters.visited) {
    andCriterion.push({
      haveVisited: true,
    });
  }

  if (filters.notVisited) {
    andCriterion.push({
      haveVisited: false,
    });
  }

  return { and: andCriterion };
};
