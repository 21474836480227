import React, { useState } from 'react';
import { Image, Header, Divider, Icon, Placeholder } from 'semantic-ui-react';
import VisitCounter from 'visits/VisitCounter';
import VisitDiary from 'visits/VisitDiary';
import { extractNodes } from 'common/utils/graphql';
import AddVisitForm from 'visits/AddVisitForm';
import SavedIcon from 'common/components/SavedIcon';
import BothyInfoBar from './BothyInfoBar';
import AnonymousUserActionGuard from 'common/components/AnonymousUserActionGuard';
import { Link } from 'react-router-dom';
import './BothyDetails.scss';

const BothyDetails = ({
  bothy,
  onSaveButtonClick,
  onAddVisitClick,
  onAddVisitFormSubmit,
  onBackClick,
  isMobile,
}) => {
  const visits = extractNodes(bothy.bothyVisits);

  return (
    <div className="BothyDetails">
      <Image src={bothy.imageUrl} />
      {isMobile && (
        <div className="floating-button save" onClick={onSaveButtonClick}>
          <SavedIcon saved={bothy.saved} />
        </div>
      )}
      <div className="floating-button back" onClick={onBackClick}>
        <Icon name="arrow left" size={isMobile ? 'large' : null} />
      </div>
      <div className="details">
        {isMobile ? (
          <VisitCounter count={bothy.bothyVisits.totalCount} />
        ) : (
          <BothyInfoBar bothy={bothy} onSaveButtonClick={onSaveButtonClick} />
        )}
        <Header content={bothy.name} subheader={bothy.summary} />
        {bothy.description && <p>{bothy.description}</p>}
      </div>
      {isMobile && <ViewLocationAction bothyId={bothy.id} />}
      <Divider />
      <AnonymousUserActionGuard action="start recording your visits">
        {isMobile ? (
          <VisitDiaryWithModal
            visits={visits}
            onAddVisitClick={onAddVisitClick}
          />
        ) : (
          <VisitDiaryWithForm
            visits={visits}
            onAddVisitFormSubmit={onAddVisitFormSubmit}
          />
        )}
      </AnonymousUserActionGuard>
    </div>
  );
};

const ViewLocationAction = ({ bothyId }) => (
  <>
    <Divider />
    <Link to={`/bothies/${bothyId}/location`}>
      <div className="location">
        <div>View location</div>
        <Icon name="arrow right" />
      </div>
    </Link>
  </>
);

const VisitDiaryWithForm = ({ visits, onAddVisitFormSubmit }) => {
  const [date, setDate] = useState(null);
  const [notes, setNotes] = useState('');

  const submitFormAndClear = () => {
    if (date) {
      onAddVisitFormSubmit({ date, notes });
      setDate(null);
      setNotes('');
    }
  };

  return (
    <>
      <VisitDiary visits={visits} />
      {!!visits.length && <Divider className="small" />}
      <AddVisitForm
        inline
        date={date}
        onDateChange={setDate}
        notes={notes}
        onNotesChange={setNotes}
        onAddClick={submitFormAndClear}
      />
    </>
  );
};

const VisitDiaryWithModal = ({ visits, onAddVisitClick }) => {
  return (
    <>
      <div className="add-visit" onClick={onAddVisitClick}>
        <Icon name="plus square outline" size="large" />
        Add a visit
      </div>
      <VisitDiary visits={visits} />
    </>
  );
};

const BothyDetailsPlaceholder = () => (
  <div className="BothyDetails Placeholder">
    <Placeholder className="image">
      <Placeholder.Image />
    </Placeholder>
    <div className="details">
      <Placeholder>
        <Placeholder.Header>
          <Placeholder.Line length="medium" />
          <Placeholder.Line length="very short" />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
          <Placeholder.Line length="full" />
        </Placeholder.Paragraph>
      </Placeholder>
    </div>
    <Divider />
    <VisitDiary.Placeholder />
  </div>
);

BothyDetails.Placeholder = BothyDetailsPlaceholder;

export default BothyDetails;
