import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import classNames from 'classnames';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { SHORT_DATE_FORMAT } from 'common/utils/dates';
import './AddVisitForm.scss';

const AddVisitForm = props => {
  const {
    bothyOptions,
    bothyId,
    onBothyIdChange,
    date,
    onDateChange,
    notes,
    onNotesChange,
    onAddClick,
    inline,
  } = props;

  const [datepickerFocussed, setDatepickerFocussed] = useState(false);

  const extraProps = inline
    ? {
        readOnly: true,
        small: true,
        anchorDirection: 'left',
        hideKeyboardShortcutsPanel: true,
        displayFormat: SHORT_DATE_FORMAT,
      }
    : {};

  return (
    <Form className={classNames('AddVisitForm', { inline: inline })}>
      <Form.Group>
        {onBothyIdChange && (
          <Form.Select
            width={11}
            label="Bothy"
            placeholder="Select a bothy"
            search
            selection
            value={bothyId}
            onChange={(event, { value }) => onBothyIdChange(value)}
            options={bothyOptions}
          />
        )}
        <Form.Field width={5}>
          {!inline && <label>Date</label>}
          <div className={classNames('date-picker', { empty: !date })}>
            <SingleDatePicker
              block={!inline}
              noBorder
              date={date}
              onDateChange={onDateChange}
              focused={datepickerFocussed}
              onFocusChange={({ focused }) => setDatepickerFocussed(focused)}
              numberOfMonths={1}
              openDirection="up"
              isOutsideRange={day => day.isAfter(moment())}
              placeholder="Pick Date"
              {...extraProps}
            />
          </div>
        </Form.Field>
      </Form.Group>
      <Form.TextArea
        className="notes"
        label={inline ? undefined : 'Notes'}
        value={notes}
        onChange={(event, { value }) => onNotesChange(value)}
      />
      {onAddClick && (
        <Form.Button
          basic
          primary
          onClick={onAddClick}
          size="tiny"
          disabled={!date}
        >
          Add Visit
        </Form.Button>
      )}
    </Form>
  );
};

export default AddVisitForm;
