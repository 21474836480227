import React from 'react';
import { Button } from 'semantic-ui-react';
import { sendVerificationEmail } from './auth';
import LoginHeader from './LoginHeader';
import './LoginForm.scss';

const VerifyEmail = ({ email, onBackClick }) => {
  return (
    <div className="VerifyEmail">
      <LoginHeader />
      <div>
        <p>An email has been sent to {email}.</p>
        <p>
          Please click the link in this email to verify your account, then try
          to login again.
        </p>
      </div>
      <Button primary onClick={sendVerificationEmail}>
        Resend verification email
      </Button>
      <Button onClick={onBackClick}>Back</Button>
    </div>
  );
};

export default VerifyEmail;
