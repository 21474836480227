import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import Authentication from 'auth/Authentication';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'styles/react-dates.scss';
import 'semantic-ui-less/semantic.less';
import './styles/base.scss';
import './index.scss';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const ProfilerApp = Sentry.withProfiler(Authentication);

ReactDOM.render(
  <Router>
    <ProfilerApp />
  </Router>,
  document.getElementById('root')
);
