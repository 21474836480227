import React from 'react';
import classNames from 'classnames';
import { Icon } from 'semantic-ui-react';

const SavedIcon = ({ saved, onClick, className }) => {
  const iconName = saved ? 'bookmark' : 'bookmark outline';

  return (
    <Icon
      className={classNames('SavedIcon', className)}
      name={iconName}
      size="large"
      onClick={onClick}
    />
  );
};

export default SavedIcon;
