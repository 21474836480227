import React from 'react';
import { useUser } from 'auth/auth';
import { useHistory } from 'react-router-dom';
import './AnonymousUserActionGuard.scss';

const AnonymousUserActionGuard = ({ action, children }) => {
  const user = useUser();

  const history = useHistory();

  if (user.isAnonymous) {
    return (
      <div className="AnonymousUserActionGuard">
        <span
          className="sign-up-link"
          onClick={() => history.push('/register')}
        >
          Sign up{' '}
        </span>{' '}
        <span className="action">to {action}</span>
      </div>
    );
  }

  return children;
};

export default AnonymousUserActionGuard;
