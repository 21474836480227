import React from 'react';
import { Image } from 'semantic-ui-react';
import Logo from 'res/logo.png';
import './LoginHeader.scss';

const LoginHeader = () => {
  return (
    <div className="LoginHeader">
      <Image src={Logo} size="small" />
      <h1>Bothy Bagger</h1>
    </div>
  );
};

export default LoginHeader;
