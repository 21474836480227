import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyBtg3tnQgjszvbwPNgyQ2fBFoNAlOX7oHU',
  authDomain: 'bothy-bagger-60229.firebaseapp.com',
  projectId: 'bothy-bagger-60229',
  storageBucket: 'bothy-bagger-60229.appspot.com',
  messagingSenderId: '206411481301',
  appId: '1:206411481301:web:4b6efe6c363271e0b8225c',
  measurementId: 'G-G6V7801C9C',
});

const auth = getAuth(firebaseApp);
auth.useDeviceLanguage();

export const googleAuthProvider = new GoogleAuthProvider();

export const getEmailAuthProvider = (email, password) =>
  EmailAuthProvider.credential(email, password);

export default auth;
