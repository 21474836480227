import React from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { ACHIEVEMENT_DETAILS_QUERY } from 'common/graphql/queries';
import {
  extractNodes,
  convertAchievementIconToComponent,
} from 'common/utils/graphql';
import LoadingWrapper from 'common/components/LoadingWrapper';
import UnlockedAchievement from './UnlockedAchievement';
import { formatDateForDisplay } from 'common/utils/dates';
import './ViewAchievementModal.scss';

const ViewAchievementModal = props => {
  const { isOpen, onClose, achievementId } = props;

  const [res] = useQuery({
    query: ACHIEVEMENT_DETAILS_QUERY,
    variables: { id: achievementId },
    pause: !isOpen,
  });

  const [achievement] = res.data ? extractNodes(res.data.achievements) : [];

  if (!achievement) return null;

  // TODO: Create a placeholder for modal content when loading
  return (
    <Modal
      className="ViewAchievementModal"
      dimmer="inverted"
      open={isOpen}
      onClose={onClose}
      closeIcon
    >
      <LoadingWrapper res={res}>
        <Modal.Header>{achievement.name}</Modal.Header>
        <Modal.Content image>
          <UnlockedAchievement>
            {convertAchievementIconToComponent(achievement.icon)}
          </UnlockedAchievement>
          <Modal.Description>
            <p className="achievement-description">{achievement.description}</p>
            <p className="achievement-date-unlocked">
              Unlocked after your visit to{' '}
              <Link
                onClick={onClose}
                to={`/bothies/${achievement.bothyVisit.bothy.id}`}
              >
                {achievement.bothyVisit.bothy.name}
              </Link>{' '}
              on {formatDateForDisplay(achievement.bothyVisit.date)}
            </p>
          </Modal.Description>
        </Modal.Content>
      </LoadingWrapper>
    </Modal>
  );
};

export default ViewAchievementModal;
