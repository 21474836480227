import React from 'react';
import { OptimizelyFeature } from '@optimizely/react-sdk';

const HiddenFeature = ({ feature, children }) => {
  return (
    <OptimizelyFeature feature={feature}>
      {isEnabled => (isEnabled ? children : null)}
    </OptimizelyFeature>
  );
};

export default HiddenFeature;
