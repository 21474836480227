import React from 'react';
import MapboxMapProvider from './map-providers/MapboxMapProvider';

const Map = props => {
  const { lat, lng, zoom, geoJsonData, onFeatureClick, children } = props;

  const bounds = [
    [-39.726078, 43.913689],
    [26.630139, 72.088421],
  ];

  return (
    <div>
      <MapboxMapProvider
        lat={lat}
        lng={lng}
        zoom={zoom}
        bounds={bounds}
        geoJsonData={geoJsonData}
        onFeatureClick={onFeatureClick}
      >
        {children}
      </MapboxMapProvider>
    </div>
  );
};

export default Map;
