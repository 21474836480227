import React, { useEffect, useState } from 'react';
import { Provider, createClient } from 'urql';
import { Route, Switch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import Root from 'Root';
import { UserContext } from './auth';
import exchanges from 'common/graphql/exchange';
import { useAuthentication } from 'auth/auth';
import LoginForm from './LoginForm';
import VerifyEmail from './VerifyEmail';
import ForgotPassword from './ForgotPassword';
import Register from './Register';

const buildClient = accessToken =>
  createClient({
    url: '/graphql',
    fetchOptions: { headers: { Authorization: `Bearer ${accessToken}` } },
    exchanges: exchanges,
  });

const Authentication = () => {
  const [client, setClient] = useState(null);

  const { user, accessToken, logout } = useAuthentication();

  useEffect(() => {
    if (accessToken) {
      setClient(buildClient(accessToken));
    }
  }, [accessToken]);

  if (user === undefined || (user && !client)) {
    return <Loader active />;
  }

  return (
    <Switch>
      <Route exact path="/register">
        <Register user={user} />
      </Route>
      <Route exact path="/forgotpassword">
        <ForgotPassword />
      </Route>
      <Route path="/">
        <Main user={user} client={client} onLogoutClick={logout} />
      </Route>
    </Switch>
  );
};

const Main = ({ user, client, onLogoutClick }) => {
  if (!user) {
    return <LoginForm />;
  }

  if (!user.isAnonymous && !user.hasVerifiedEmail) {
    return <VerifyEmail email={user.email} onBackClick={onLogoutClick} />;
  }

  return (
    <UserContext.Provider value={user}>
      <Provider value={client}>
        <Root onLogoutClick={onLogoutClick} />
      </Provider>
    </UserContext.Provider>
  );
};

export default Authentication;
