import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useQuery } from 'urql';
import { extractNodes } from 'common/utils/graphql';
import BothyVisitTimeline from 'bothies/visit-timeline/BothyVisitTimeline';
import AddVisitModal from 'visits/AddVisitModal';
import { BOTHY_VISITS_QUERY } from 'common/graphql/queries';
import LoadingWrapper from 'common/components/LoadingWrapper';
import './VisitsTab.scss';
import AnonymousUserActionGuard from 'common/components/AnonymousUserActionGuard';

const VisitsTab = () => {
  const history = useHistory();

  const [res] = useQuery({
    query: BOTHY_VISITS_QUERY,
  });

  const visits = res.data ? extractNodes(res.data.bothyVisits) : [];

  const [isAddVisitModalOpen, setIsAddVisitModalOpen] = useState(false);

  const memoizedOnAddVisitModalClose = useCallback(
    () => setIsAddVisitModalOpen(false),
    [setIsAddVisitModalOpen]
  );

  const navigateToBothyDetailsPage = bothyId =>
    history.push(`/bothies/${bothyId}`);

  return (
    <div className="VisitsTab tab">
      <AnonymousUserActionGuard action="start recording your visits">
        <div className="add-visit" onClick={() => setIsAddVisitModalOpen(true)}>
          <Icon name="plus square outline" size="large" />
          Add a visit
        </div>
      </AnonymousUserActionGuard>
      <LoadingWrapper
        res={res}
        placeholder={<BothyVisitTimeline.Placeholder />}
      >
        <BothyVisitTimeline
          visits={visits}
          onBothyClick={navigateToBothyDetailsPage}
        />
      </LoadingWrapper>
      <AddVisitModal
        isOpen={isAddVisitModalOpen}
        onClose={memoizedOnAddVisitModalClose}
      />
    </div>
  );
};

export default VisitsTab;
