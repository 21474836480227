import React from 'react';
import { useQuery } from 'urql';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useMutationWithErrorToast } from 'common/hooks/mutation';
import { extractNodes } from 'common/utils/graphql';
import BothyList from 'bothies/BothyList';
import { BOTHIES_LIST_QUERY } from 'common/graphql/queries';
import { TOGGLE_SAVED_BOTHY_MUTATION } from 'common/graphql/mutations';
import LoadingWrapper from 'common/components/LoadingWrapper';
import './BothyListContainer.scss';

const BothyListContainer = ({ variables, className, header, emptyMessage }) => {
  const history = useHistory();

  const [res] = useQuery({
    query: BOTHIES_LIST_QUERY,
    variables,
    requestPolicy: 'cache-and-network',
  });

  const executeToggleSavedBothy = useMutationWithErrorToast({
    mutation: TOGGLE_SAVED_BOTHY_MUTATION,
    errorMessage:
      'An error occurred while trying to save the bothy, please try again.',
  });

  const bothies = res.data ? extractNodes(res.data.bothies) : [];

  const navigateToBothyDetailsPage = bothyId =>
    history.push(`/bothies/${bothyId}`);

  return (
    <LoadingWrapper
      res={res}
      placeholder={<Placeholder header={header} className={className} />}
    >
      <div
        className={classNames(
          'BothyListContainer',
          { empty: !bothies.length },
          className
        )}
      >
        {header}
        {bothies.length ? (
          <BothyList
            bothies={bothies}
            onBothyClick={navigateToBothyDetailsPage}
            onSaveBothyClick={executeToggleSavedBothy}
          />
        ) : (
          <div className="empty-message">{emptyMessage}</div>
        )}
      </div>
    </LoadingWrapper>
  );
};

const Placeholder = ({ header, className }) => (
  <div className={classNames('BothyListContainer', className)}>
    {header}
    <BothyList.Placeholder />
  </div>
);

export default BothyListContainer;
