import React from 'react';
import { Label } from 'semantic-ui-react';
import './BothyFilterPanel.scss';

const BothyFilterPanel = ({
  filters,
  onFiltersChange,
  showClearFiltersButton = true,
  basic = false,
}) => {
  const toggleFilter = filterName => () => {
    const updatedFilters = { ...filters, [filterName]: !filters[filterName] };
    onFiltersChange(updatedFilters);
  };

  const filtersApplied = Object.entries(filters).some(
    ([_filter, enabled]) => enabled
  );

  const clearFilters = () => onFiltersChange({});

  const getFilterColor = filter => (filter ? 'brown' : undefined);

  const buildButtonFilterProps = name => ({
    basic: true,
    className: 'button-filter',
    size: 'large',
    onClick: toggleFilter(name),
    color: getFilterColor(filters[name]),
  });

  return (
    <div className="BothyFilterPanel">
      <div className="primary-filters">
        <Label {...buildButtonFilterProps('visited')}>Visited</Label>
        <Label {...buildButtonFilterProps('notVisited')}>Not visited</Label>
        {!basic && <Label {...buildButtonFilterProps('saved')}>Saved</Label>}
      </div>
      {filtersApplied && showClearFiltersButton && (
        <div className="clear-filters" onClick={clearFilters}>
          Clear filters
        </div>
      )}
    </div>
  );
};

export default BothyFilterPanel;
