import { BOTHY_DETAILS_QUERY, BOTHY_VISITS_QUERY } from './queries';

export const toggleSavedBothyOptimisticUpdater = (variables, cache) => {
  const bothy = {
    __typename: 'Bothy',
    id: variables.bothyId,
  };
  const currentlySaved = cache.resolve(bothy, 'saved');

  return {
    bothy: { ...bothy, saved: !currentlySaved },
    __typename: 'ToggleSavedBothy',
  };
};

export const addBothyVisitUpdater = (result, args, cache) => {
  cache.updateQuery(
    { query: BOTHY_DETAILS_QUERY, variables: { id: args.bothyId } },
    data => {
      if (data) {
        data.bothy.bothyVisits.edges.push({
          node: result.addBothyVisit.bothyVisit,
        });
        data.bothy.bothyVisits.edges.sort(
          (a, b) => a.node.date.valueOf() - b.node.date.valueOf()
        );
      }
      return data;
    }
  );
  cache.updateQuery({ query: BOTHY_VISITS_QUERY }, data => {
    if (data) {
      data.bothyVisits.edges.push({
        node: result.addBothyVisit.bothyVisit,
      });
      data.bothyVisits.edges.sort(
        (a, b) => a.node.date.valueOf() - b.node.date.valueOf()
      );
    }
    return data;
  });
  cache.invalidate('Query', 'achievements', { filters: { isUnlocked: true } });
  cache.invalidate('Query', 'achievementGroups');

  cache.invalidate('Query', 'bothies', {
    filters: { and: [{ isSaved: true }, { haveVisited: false }] },
    sort: 'NAME_ASC',
  });
  cache.invalidate('Query', 'bothies', {
    filters: { and: [{ isSaved: true }, { haveVisited: true }] },
    sort: 'NAME_ASC',
  });
  cache.invalidate('Query', 'bothies', {
    filters: { and: [{ haveVisited: false }] },
    sort: 'NAME_ASC',
  });
  cache.invalidate('Query', 'bothies', {
    filters: { and: [{ haveVisited: true }] },
    sort: 'NAME_ASC',
  });
};
