import React from 'react';
import { Loader } from 'semantic-ui-react';
import './LoadingWrapper.scss';

const LoadingWrapper = ({ res, placeholder = <Loader active />, children }) => {
  if (res.error) {
    return (
      <div className="LoadingWrapper-error">
        <div>Something went wrong, please try again</div>
      </div>
    );
  }

  return res.fetching ? placeholder : children;
};

export default LoadingWrapper;
