import React from 'react';
import SuggestionSearchBar from 'controls/SuggestionSearchBar';
import BothyFilterPanel from 'controls/BothyFilterPanel';
import './SearchFilterBar.scss';
import useMobile from 'common/hooks/mobile';

const SearchFilterBar = ({
  onSuggestionSelected,
  filters,
  onFiltersChange,
}) => {
  const isMobile = useMobile();

  return (
    <div className="SearchFilterBar">
      <BothyFilterPanel
        filters={filters}
        onFiltersChange={onFiltersChange}
        basic={isMobile}
      />
      <SuggestionSearchBar onSuggestionSelected={onSuggestionSelected} />
    </div>
  );
};

export default SearchFilterBar;
