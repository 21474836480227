export const mapBothiesToGeoJson = bothies => {
  const bothyFeatures = bothies.map(x => ({
    type: 'Feature',
    properties: {
      id: x.id,
      name: x.name,
      summary: x.summary,
    },
    geometry: {
      type: 'Point',
      coordinates: [x.longitude, x.latitude],
    },
  }));

  return {
    type: 'FeatureCollection',
    crs: {
      type: 'name',
      properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
    },
    features: bothyFeatures,
  };
};
