import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import './BothyPopup.scss';
import { Link } from 'react-router-dom';

const BothyPopup = props => {
  const { id, name, summary, onCloseClick } = props;

  return (
    <div className="BothyPopup">
      <Icon className="clickable" name="close" onClick={onCloseClick} />
      <Link to={`/bothies/${id}`}>
        <Header>
          {name}
          <Header.Subheader>{summary}</Header.Subheader>
        </Header>
      </Link>
    </div>
  );
};

export default BothyPopup;
