import React from 'react';
import { Card, Placeholder } from 'semantic-ui-react';
import classNames from 'classnames';
import LazyImage from 'common/components/LazyImage';
import './BothyCard.scss';

const BothyCard = props => {
  const { bothy, onCardClick, isMobile, children } = props;

  return (
    <Card
      className={classNames('BothyCard', { horizontal: !isMobile })}
      onClick={onCardClick}
      link={!!onCardClick}
      as="div"
    >
      <LazyImage src={bothy.imageUrl} wrapped ui={false} />
      <div className="details">
        <Card.Content>
          <Card.Header>{bothy.name}</Card.Header>
          <Card.Meta>
            <span>{bothy.region.name}</span>
          </Card.Meta>
          {!isMobile && <Card.Description>{bothy.summary}</Card.Description>}
        </Card.Content>
        <Card.Content extra>{children}</Card.Content>
      </div>
    </Card>
  );
};

const BothyCardPlaceholder = ({ isMobile }) => {
  return (
    <Card className="BothyCard Placeholder" as="div">
      <Placeholder className="image">
        <Placeholder.Image />
      </Placeholder>
      <div className="details">
        <Card.Content>
          <Placeholder>
            <Placeholder.Header>
              <Placeholder.Line length="long" />
              <Placeholder.Line length="short" />
            </Placeholder.Header>
            {!isMobile && (
              <Placeholder.Paragraph>
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
                <Placeholder.Line length="full" />
              </Placeholder.Paragraph>
            )}
          </Placeholder>
        </Card.Content>
      </div>
    </Card>
  );
};

BothyCard.Placeholder = BothyCardPlaceholder;

export default BothyCard;
