import { isDevelopmentMode } from 'common/utils/env';
import React, { useContext, useEffect, useState } from 'react';
import auth, { getEmailAuthProvider, googleAuthProvider } from './firebase';
import {
  onIdTokenChanged,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInAnonymously as firebaseSignInAnonymously,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  sendEmailVerification,
  linkWithCredential,
  linkWithPopup,
} from 'firebase/auth';

export const AuthenticationErrorCodes = {
  EMAIL_EXISTS_ALREADY: 'email-exists-already',
  WEAK_PASSWORD: 'weak-password',
  ACCOUNT_DISABLED: 'account-disabled',
  INVALID_DETAILS: 'invalid-details',
  TOO_MANY_ATTEMPTS: 'too-many-attempts',
};
export class AuthenticationError extends Error {
  constructor(code = 'general-auth', ...params) {
    super(...params);

    this.name = 'AuthenticationError';
    this.code = code;
  }
}

const parseFirebaseError = firebaseError => {
  const { code } = firebaseError;

  switch (code) {
    case 'auth/email-already-in-use':
      throw new AuthenticationError(
        AuthenticationErrorCodes.EMAIL_EXISTS_ALREADY,
        'An account with this email address already exists'
      );

    case 'auth/weak-password':
      throw new AuthenticationError(
        AuthenticationErrorCodes.WEAK_PASSWORD,
        'Password should be at least 6 characters'
      );

    case 'auth/user-disabled':
      throw new AuthenticationError(
        AuthenticationErrorCodes.ACCOUNT_DISABLED,
        'This account has been disabled'
      );

    case 'auth/wrong-password':
    case 'auth/invalid-email':
    case 'auth/user-not-found':
      throw new AuthenticationError(
        AuthenticationErrorCodes.INVALID_DETAILS,
        'Incorrect email address or password'
      );

    case 'auth/too-many-requests':
      throw new AuthenticationError(
        AuthenticationErrorCodes.TOO_MANY_ATTEMPTS,
        'Too many failed attempts, please try again later'
      );

    default:
      console.error(code, firebaseError.message);
      throw new AuthenticationError('An unknown error occurred');
  }
};

export const useAuthentication = () => {
  const [user, setUser] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);

  useEffect(() => {
    onIdTokenChanged(auth, async user => {
      const profile = user
        ? {
            email: user.email,
            uid: user.uid,
            isAnonymous: user.isAnonymous,
            hasVerifiedEmail: user.emailVerified,
          }
        : null;
      setUser(profile);

      if (user) {
        const token = await user.getIdToken();
        setAccessToken(token);
      }
    });
  }, []);

  if (isDevelopmentMode()) {
    return {
      user: {
        email: 'dev@bothybagger.co.uk',
        uid: 'mnEFdwjnIEYEc10RrppR9naLNPq1',
        isAnonymous: false,
        hasVerifiedEmail: true,
      },
      accessToken: 'dev',
      logout: () => {},
    };
  }

  return { user, accessToken, logout: () => signOut(auth) };
};

export const UserContext = React.createContext();
export const useUser = () => {
  const userContext = useContext(UserContext);
  return userContext;
};

export const convertAnonymousUserToGoogleUser = async () => {
  const anonymousUser = auth.currentUser;

  linkWithPopup(anonymousUser, googleAuthProvider);
};

export const convertAnonymousUserToPasswordUser = async (email, password) => {
  const anonymousUser = auth.currentUser;

  try {
    await linkWithCredential(
      anonymousUser,
      getEmailAuthProvider(email, password)
    );

    await sendVerificationEmail();
  } catch (error) {
    parseFirebaseError(error);
  }
};

export const signUpWithEmailAndPassword = async (email, password) => {
  try {
    await createUserWithEmailAndPassword(auth, email, password);

    await sendVerificationEmail();
  } catch (error) {
    parseFirebaseError(error);
  }
};

export const signInWithEmail = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (e) {
    parseFirebaseError(e);
  }
};

export const signInWithGoogle = async () => {
  signInWithPopup(auth, googleAuthProvider);
};

export const signInAnonymously = async () => {
  const result = await firebaseSignInAnonymously(auth);
  return result;
};

export const sendVerificationEmail = async () => {
  const result = await sendEmailVerification(auth.currentUser, {
    url: 'https://bothybagger.co.uk',
  });
  return result;
};

export const sendPasswordResetEmail = async email => {
  await firebaseSendPasswordResetEmail(auth, email, {
    url: 'https://bothybagger.co.uk',
  });
};
