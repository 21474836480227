import React from 'react';
import { useQuery } from 'urql';
import { Icon, Label } from 'semantic-ui-react';
import { ACHIEVEMENT_SUMMARY_QUERY } from 'common/graphql/queries';

const AchievementSummary = () => {
  const [res] = useQuery({
    query: ACHIEVEMENT_SUMMARY_QUERY,
  });

  const achievementCount =
    res.data && res.data.achievements ? res.data.achievements.totalCount : null;

  return (
    <>
      {achievementCount !== null && (
        <Label color="orange" size="tiny">
          {achievementCount}
        </Label>
      )}
      <Icon size="big" name="gem outline" />
    </>
  );
};

export default AchievementSummary;
