import React, { useState } from 'react';
import { Icon, Form, Divider, Message } from 'semantic-ui-react';
import {
  signInAnonymously,
  signInWithEmail,
  signInWithGoogle,
  AuthenticationError,
  AuthenticationErrorCodes,
} from './auth';
import { useHistory } from 'react-router-dom';
import LoginHeader from './LoginHeader';
import './LoginForm.scss';

const LoginForm = () => {
  const [loggingInWithEmail, setLoggingInWithEmail] = useState(false);
  const [error, setError] = useState(null);

  const history = useHistory();

  const onError = error => setError(error);

  return (
    <div className="LoginForm">
      <LoginHeader />
      <Form error={!!error}>
        <Message error content={error} />
        {loggingInWithEmail ? (
          <EmailSignInForm
            onForgotPasswordClick={() => history.push('/forgotpassword')}
            onError={onError}
          />
        ) : (
          <Form.Button
            primary
            icon
            fluid
            labelPosition="left"
            onClick={() => setLoggingInWithEmail(true)}
          >
            <Icon name="mail outline" /> Sign in with email
          </Form.Button>
        )}
        <Form.Button
          color="blue"
          icon
          fluid
          labelPosition="left"
          onClick={signInWithGoogle}
        >
          <Icon name="google" /> Sign in with Google
        </Form.Button>

        <br></br>
        <Divider />
        <p>
          Don't have an account?{' '}
          <span className="link-text" onClick={() => history.push('/register')}>
            Create one
          </span>{' '}
          or{' '}
          <span className="link-text" onClick={signInAnonymously}>
            explore without an account
          </span>
          .
        </p>
      </Form>
    </div>
  );
};

const EmailSignInForm = ({ onForgotPasswordClick, onError }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailSignIn = async () => {
    if (!email || !password) {
      return;
    }

    try {
      await signInWithEmail(email, password);
    } catch (error) {
      if (error instanceof AuthenticationError) {
        if (error.code === AuthenticationErrorCodes.ACCOUNT_DISABLED) {
          onError(
            <>
              Your account has been disabled. Please contact{' '}
              <a href="mailto:gavin@bothybagger.co.uk?subject=Account Disabled">
                gavin@bothybagger.co.uk
              </a>{' '}
              for more information.
            </>
          );
        } else {
          onError(error.message);
        }
        setPassword('');
      }
    }
  };

  return (
    <>
      <Form.Input
        type="email"
        required
        placeholder="Email address"
        value={email}
        onChange={(_, { value }) => setEmail(value)}
      />
      <Form.Input
        type="password"
        required
        placeholder="Password"
        value={password}
        onChange={(_, { value }) => setPassword(value)}
      />
      <p className="forgot-password-link">
        <span className="link-text secondary" onClick={onForgotPasswordClick}>
          Forgot password?
        </span>
      </p>
      <Form.Button
        primary
        icon
        fluid
        labelPosition="left"
        onClick={handleEmailSignIn}
      >
        Sign in
      </Form.Button>
    </>
  );
};

export default LoginForm;
