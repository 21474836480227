import moment from 'moment';
import { PLACEHOLDER_URL } from 'common/utils/constants';

export const bothyVisitResolver = {
  date: parent => moment(parent.date),
};

export const achievementResolver = {
  unlockedAt: parent => moment(parent.unlockedAt),
};

export const bothyResolver = {
  imageUrl: parent => parent.imageUrl || PLACEHOLDER_URL,
};
