import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Icon,
  Menu,
  Segment,
  Sidebar as SemanticSidebar,
  Image,
} from 'semantic-ui-react';
import Logo from 'res/logo.png';
import { sidebarRoutes } from 'MobileRoot';
import HiddenFeature from 'common/components/HiddenFeature';
import './Sidebar.scss';

const Sidebar = props => {
  const { children, isVisible, onSidebarHide } = props;

  const history = useHistory();
  const location = useLocation();

  const generateItemProps = route => {
    const navigatePath = route.navigatePath || route.path;
    const active = route.activePath
      ? location.pathname.includes(route.activePath)
      : location.pathname === route.path;

    return {
      onClick: () => {
        onSidebarHide();
        history.push(navigatePath);
      },
      active,
    };
  };

  return (
    <SemanticSidebar.Pushable className="Sidebar" as={Segment}>
      <SemanticSidebar
        as={Menu}
        animation="overlay"
        onHide={onSidebarHide}
        vertical
        visible={isVisible}
        width="thin"
        size="massive"
      >
        <Menu.Item header className="borderless">
          Bothy Bagger
        </Menu.Item>

        <Menu.Item>
          <Image alt="bothy bagger logo" src={Logo} size="tiny" centered />
        </Menu.Item>

        {sidebarRoutes.map(r => {
          const menuItem = (
            <Menu.Item key={r.path} {...generateItemProps(r)}>
              <Icon name={r.sidebarIcon} />
              {r.title}
            </Menu.Item>
          );

          if (!r.featureFlag) {
            return menuItem;
          }

          return (
            <HiddenFeature key={r.path} feature={r.featureFlag}>
              {menuItem}
            </HiddenFeature>
          );
        })}
      </SemanticSidebar>

      <SemanticSidebar.Pusher>{children}</SemanticSidebar.Pusher>
    </SemanticSidebar.Pushable>
  );
};

export default Sidebar;
