import { pluralise } from 'common/utils/text';
import React from 'react';
import { Divider, Icon, Placeholder, Popup } from 'semantic-ui-react';
import VisitDate from './VisitDate';
import './VisitDiary.scss';

const VisitDiary = ({ visits }) => {
  return (
    <div className="VisitDiary">
      {!!visits.length &&
        visits
          .map(x => <VisitDiaryEntry key={x.id} visit={x} />)
          .reduce((prev, curr) => [
            prev,
            <Divider key={prev.toString()} className="small" />,
            curr,
          ])}
    </div>
  );
};

const VisitDiaryEntry = ({ visit }) => {
  return (
    <div className="VisitDiaryEntry">
      <div className="header">
        <VisitDate color="accent" date={visit.date} />
        {!!visit.achievements.totalCount && (
          <Popup
            trigger={<Icon name="gem outline" color="grey" />}
            content={`You earned ${pluralise(
              visit.achievements.totalCount,
              'achievement'
            )} from this visit`}
            position="right center"
            on={['hover', 'click']}
          />
        )}
      </div>
      <p>{visit.notes}</p>
    </div>
  );
};

const VisitDiaryPlaceholder = () => (
  <div className="VisitDiary">
    <VisitDiaryEntryPlaceholder />
    <Divider className="small" />
    <VisitDiaryEntryPlaceholder />
    <Divider className="small" />
    <VisitDiaryEntryPlaceholder />
  </div>
);

const VisitDiaryEntryPlaceholder = () => (
  <div className="VisitDiaryEntry">
    <Placeholder>
      <Placeholder.Header>
        <Placeholder.Line length="short" />
      </Placeholder.Header>
      <Placeholder.Paragraph>
        <Placeholder.Line length="full" />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    </Placeholder>
  </div>
);

VisitDiary.Placeholder = VisitDiaryPlaceholder;

export default VisitDiary;
