import React, { useState } from 'react';
import { Search } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { useQuery } from 'urql';
import { BOTHY_SUGGESTION_SEARCH_QUERY } from 'common/graphql/queries';
import { extractNodes } from 'common/utils/graphql';
import './SuggestionSearchBar.scss';

const SuggestionSearchBar = ({ disabled, onSuggestionSelected }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const [res] = useQuery({
    query: BOTHY_SUGGESTION_SEARCH_QUERY,
    variables: { searchTerm: `%${searchTerm}%` },
  });

  const mapQueryResultsToSuggestions = data => {
    if (!data) {
      return [];
    }

    return {
      // TODO: Remove this or make it production ready
      // regions: {
      //   name: 'Regions',
      //   results: extractNodes(data.regions).map(x => ({
      //     id: x.id,
      //     title: x.name,
      //   })),
      // },
      bothies: {
        name: 'Bothies',
        results: extractNodes(data.bothies).map(x => ({
          id: x.id,
          title: x.name,
        })),
      },
    };
  };

  const handleResultSelect = result => {
    setSearchTerm(result.title);
    onSuggestionSelected(result);
  };

  return (
    <Search
      size="large"
      placeholder="Search"
      className="SuggestionSearchBar"
      fluid
      category
      loading={res.fetching}
      onResultSelect={(_, { result }) => handleResultSelect(result)}
      onSearchChange={debounce((_, { value }) => setSearchTerm(value), 500, {
        leading: true,
      })}
      results={mapQueryResultsToSuggestions(res.data)}
      value={searchTerm}
    />
  );
};

export default SuggestionSearchBar;
